$break-large: 2500px;
$break-medium: 1500px;
$break-mobile: 1000px;

.yearPreview {
  min-width: 1200px;
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 200px;

  @media screen and (min-width: $break-large) {
    width: 1770px;
  }
  @media screen and (max-width: $break-medium) {
    width: 885px;
    min-width: initial;
  }
  @media screen and (max-width: $break-mobile) {
    width: 300px;
    min-width: initial;
  }

  @media print {
    page-break-after: always;
    width: 1800px;

    .eventPreview:hover > .title::after {
      content: '';
    }
  }

  h1 {
    font-weight: normal;
  }
  .title {
    cursor: pointer;
    user-select: none;
  }
    & > .title:hover::after {
      content: '↕';
      display: block;
      margin: 0 auto;
      font-size: 40px;
      text-align: center;
      position: absolute;
      bottom: -105px;
      left: 47%;

      @media screen and (max-width: $break-mobile) {
        display: none;
      }
    }

  @media screen and (min-width: $break-large) {
    & > .title {
      // font-size: 8.25em; // Playfair
      font-size: 8.15em; // Julius Sans One
    }
  }
  @media screen and (max-width: $break-medium) {
    & > .title {
      // font-size: 4.1em; // Playfair
      font-size: 4em; // Julius Sans One
    }
  }
  @media screen and (max-width: $break-mobile) {
    & > .title {
      // font-size: 1.5em;
      font-size: 1.7em; // Julius Sans One
    }
  }


  .events {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 51.5px;
    grid-row-gap: 80px;

    &.short {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: $break-large) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media screen and (max-width: $break-medium) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: $break-mobile) {
      grid-template-columns: 1fr;
      &.short {
        grid-template-columns: 1fr;
      }
    }
    @media print {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &.past > .title {
    opacity: 0.2;

    @media print {
      opacity: 1;
    }
  }
  &.past > .title:hover {
    opacity: 0.8;
    @media print {
      opacity: 1;
    }
  }

  .information {
    margin-bottom: 100px;
    line-height: 2em;
    text-align: justify;

    &.hidden {
      margin-bottom: 0px;
      height: 0;
      overflow: hidden;
    }

    .content {
      column-count: 2;
      column-gap: 2em;
      @media screen and (max-width: $break-mobile) {
        column-count: 1;
      }

      p {
        font-size: 1.25rem;
        margin: 0em;
        margin-bottom: 2em;
      }

      .image {
        margin: 0;
      }
    }


  }
}
