$break-mobile: 1000px;

.eventPreview {
  margin-top: 20px;
  font-size: 1.25rem;

  &.past {
    opacity: 0.2;
    @media print {
      opacity: 1;
    }
  }

  &.past:hover {
    opacity: 0.8;
    @media print {
      opacity: 1;
    }
  }

  div {
    margin-bottom: 25px;
  }

  .title {
    position: relative;
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: 5px;
    margin-bottom: 0.25em;
    letter-spacing: 0.02em;
  }

  .arrow {
    visibility: hidden;
    position: relative;
    left: 1em;
    bottom: 0.25em;
    content: '⟶ ';
    font-size: 14px;

    @media print {
      content: '';
    }
  }

  &:hover .arrow {
    visibility: visible;
  }

  .date {
    border-bottom: 1px solid #333;
  }

  .description {
    text-align: left;
    line-height: 1.5em;
  }


  @media screen and (max-width: $break-mobile) {
    max-width: 300px;
    overflow-wrap: break-word;
  }
}
