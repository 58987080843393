@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,700|Ultra|Lusitana);
@import url('https://fonts.googleapis.com/css?family=Julius+Sans+One');

body {
  margin: 0;
  padding: 0;
  font-family: "Playfair Display", serif;
  font-size: 100%;
  min-height: 100%;
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
  border-bottom: 1px solid transparent;
  /* border-bottom: 1px dotted #666; */
}

h1 {
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-bottom: 100px;
  color: #333;
  /* font-size: 5.5rem; */
  font-size: 5.52rem;
  letter-spacing: 0.5em;
  line-height: 1.6em;
  text-transform: uppercase;
  font-family: 'Julius Sans One'
}

ul {
  padding-left: 1.5rem;
}
