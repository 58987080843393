$break-mobile: 1000px;

.page {
  min-width: 1200px;
  width: 1200px;
  margin: 0 auto;
  padding: 10px;
  padding-bottom: 100px;
  min-height: 100vh;

  @media screen and (max-width: $break-mobile) {
    max-width: 100%;
    min-width: initial;
  }

  h1 {
    margin-bottom: 100px;

    @media screen and (max-width: $break-mobile) {
      & {
        font-size: 1.5em;
      }
    }
  }

  h2 {
    margin-bottom: 100px;
    margin-top: 0;
    position: relative;
    font-size: 2rem;

    @media screen and (max-width: $break-mobile) {
      & {
        font-size: 1em;
      }
    }

  }

  a {
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px dotted #333;
    }
  }
    a.festivalTitle {
      border-bottom: 1px dotted transparent;
    }

    a.festivalTitle:hover {
      border-bottom: 1px dotted #333;
    }

    .festivalTitle:hover::before {
      content: '⟵ ';
      position: absolute;
      left: -42px;
      bottom: 12px;
      font-size: 14px;
    }

  .content {
    width: 50%;
    line-height: 2em;
    text-align: justify;

    @media screen and (max-width: $break-mobile) {
      width: 75%;
    }
  }
}
