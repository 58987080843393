.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5rem;
}

.App-intro {
  font-size: large;
}
