$break-large: 2500px;
$break-medium: 1500px;
$break-mobile: 1000px;

.eventPage {

  min-width: 1200px;
  width: 1200px;
  margin: 0 auto;
  padding: 10px;
  padding-bottom: 200px;
  min-height: 100vh;

  @media screen and (max-width: $break-mobile) {
    max-width: 100%;
    min-width: initial;
    width: initial;
    margin: 20px;
    margin-top: 0;
    padding-top: 0;
  }


  h1 {
    margin-bottom: 100px;
    font-weight: normal;

    @media screen and (max-width: $break-mobile) {
      & {
        font-size: 1.5em;
      }
    }
  }

  .festivalTitle {
    position: relative;
    margin: 0;
    color: #333;
  }

    a.festivalTitle {
      border-bottom: 1px dotted transparent;
    }

    a.festivalTitle:hover {
      border-bottom: 1px dotted #333;
    }

    .festivalTitle:hover::before {
      content: '⟵ ';
      position: absolute;
      left: -42px;
      bottom: 12px;
      // color: #666;
      font-size: 14px;
    }

  h2 {
    margin-bottom: 100px;
    margin-top: 0;
    position: relative;
    font-size: 2rem;

    .date, .venue, .price, a {
      display: inline-block;
      color: #333;
    }
    .venue {
      display: block;
    }

    .venue:hover::after {
      content: '⟶ ';
      position: relative;
      left: 15px;
      bottom: 4px;
      font-size: 14px;
      color: #333;
    }

    .price {
      display: block;
    }

    @media screen and (max-width: $break-mobile) {
      & {
        font-size: 1.25em;
      }

      .venue::after {
        content: '⟶ ';
        position: relative;
        left: 15px;
        bottom: 0px;
        font-size: 14px;
        color: #333;
      }
    }

  }

  a {
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px dotted #333;
    }
  }

  p {
    margin-top: 0;
  }


  .pageContent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 51.5px;
    grid-row-gap: 80px;

    @media screen and (min-width: $break-large) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: $break-medium) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: $break-mobile) {
      grid-template-columns: 1fr;
      &.short {
        grid-template-columns: 1fr;
      }
    }
    @media print {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .description {
    line-height: 2em;
    text-align: justify;
    grid-column: 1 / span 2;
    @media screen and (max-width: $break-mobile) {
      grid-column: 1;
    }
  }

  .mapouter{
    position:relative;
    text-align:right;
    height:295px;
    width:295px;
    margin-top: 100px;
  }
  .gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:295px;
    width:295px;
  }
}
