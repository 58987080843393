$break-large: 2500px;
$break-medium: 1500px;
$break-mobile: 1000px;

.videoPage {
  .arrow {
    visibility: hidden;
    position: relative;
    left: 1em;
    bottom: 0.025em;
    content: '⟶ ';
    font-size: 14px;
  }

  .headerGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 51.5px;

    .title {
      grid-column: 1/3 span;

      @media screen and (max-width: $break-mobile) {
        & {
          grid-column: initial;
        }
      }
    }
  }

  .pageContent {
    .content {
      h2 {
        margin-bottom: 2rem;
      }
    }


    .title {
      margin: 0;
      grid-column: 2/3 span;
      @media screen and (max-width: $break-mobile) {
        & {
          grid-column: initial;
        }
      }
    }

    .date {
      border-bottom: 1px solid #333;
    }

    .videoEmbed {
      grid-column: 2/3 span;
      @media screen and (max-width: $break-mobile) {
        & {
          grid-column: initial;
        }
      }
    }

    .screeningMenu {
      h2:hover .arrow {
        visibility: visible;
      }

      h2 .arrow {
        position: absolute;
        top: 0.5em;
        left: -3em;
      }

      &.mobile {
        display: none;
      }

      @media screen and (max-width: $break-mobile) {
        & {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }

      ol {
        padding: 0;
      }

      p:hover .arrow {
        visibility: visible;
      }

    }

    .info {
      grid-column: 2/2 span;

      @media screen and (max-width: $break-mobile) {
        & {
          grid-column: initial;
        }
      }
    }
  }
}
