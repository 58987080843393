$break-mobile: 1000px;

.eventPreview.videoPreview {
  position: relative;
  margin-top: 0px;

  .thumb {
    img {
      width: 100%;
    }
  }
}
